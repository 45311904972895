import React from "react";
import QrReader from 'react-qr-reader'
import Header from "../header";
import {Link} from "react-router-dom";

export default class QrScanner extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      result: ''
    }
  }

  handleScan = data => {
    if (data) {
      this.setState({
        result: data
      })
    }
  }

  handleError = err => {
    console.error(err)
  }

  getResultDisplay() {
    if (this.state.result) {
      return (<Link to={{pathname: `/order/${this.state.result}`}}>Bestellung {this.state.result} anzeigen</Link>);
    } else {
      return '';
    }
  }

  render(props) {
    return (
      <div className={'order-overview'}>
        <Header title={'QR-Code-Scanner'}/>
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          className={'qr-scanner'}
        />
        <div className={'qr-result'}>{this.getResultDisplay()}</div>
      </div>
    );
  }
}
