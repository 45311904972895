import React from "react";
import Pickadate from "pickadate/builds/react-dom";
import {Link} from "react-router-dom";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      template: props.template,
      showDatepicker: false
    };
    this.toggleDatepicker = this.toggleDatepicker.bind(this);
  }

  componentDidMount(props) {
    this.setState({
      isLoaded: true,
    });
  }

  toggleDatepicker() {
    this.setState({
      showDatepicker: !this.state.showDatepicker
    });
  }

  getTitle() {
    if (this.state.template === 'order-overview') {
      return (<h1 onClick={this.toggleDatepicker}>{this.props.title}</h1>);
    }
    return (<h1>{this.props.title}</h1>);
  }

  getDatepicker() {
    if (!this.state.showDatepicker) {
      return;
    }

    return (
      <div className={'pickadate-wrapper'}>
        <Pickadate onChangeValue={({value, date}) => {
          console.log('Selected date: ' + date);
          this.props.updateSelectedDate(date);
          this.toggleDatepicker();
        }}>
          <Pickadate.DatePicker/>
        </Pickadate>
      </div>
    )
  }


  render() {
    if (this.state.error) {
      return <div>Error: {this.state.error.message}</div>;
    } else if (!this.state.isLoaded) {
      return 'Loading...';
    } else {
      return (
        <header className="main-header">
          <Link to={{pathname: `/`}}>
            <div className="main-logo"/>
          </Link>

          {this.getTitle()}
          {this.getDatepicker()}
        </header>
      );
    }
  }
}
