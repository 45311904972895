import React from "react";

export default class OrderDetailOrderInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      orderInfo: this.props.orderInfo,
      infoBoxActive: true
    };
    this.toggleInfoBox = this.toggleInfoBox.bind(this);
  }

  componentDidMount() {
    this.setState({
      isLoaded: true,
    });
  }

  getInfoBoxHeader() {
    return (
      <>
        <div className={'order-info__header--date'}>
          <div className={'icon'}/>
          {this.state.orderInfo.orderDate}, {this.state.orderInfo.orderTime}
        </div>
        <div className={'order-info__header--customer-name'}>
          <div className={'icon'}/>
          {this.state.orderInfo.customerName}
        </div>
        <div className={'order-info__header--order-status'}>
          <div className={'icon'}/>
          {this.state.orderInfo.statusName}
        </div>
      </>
    );
  }

  getShippingInfo() {
    return (
      <div className={'order-info__shipping-info'}>
        <h4>Adresse</h4>
        {this.state.orderInfo.shippingAddress.first_name} {this.state.orderInfo.shippingAddress.last_name}
        <br/>
        {this.state.orderInfo.shippingAddress.address_1}
        <br/>
        {this.state.orderInfo.shippingAddress.postcode}, {this.state.orderInfo.shippingAddress.city}
      </div>
    );
  }

  getTotals() {
    let discount = parseInt(this.state.orderInfo.totals.discount).toLocaleString('de-AT', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    let shippingTotal = parseInt(this.state.orderInfo.totals.shippingTotal).toLocaleString('de-AT', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    let orderTotal = parseInt(this.state.orderInfo.totals.total).toLocaleString('de-AT', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    let totalTax = parseInt(this.state.orderInfo.totals.totalTax).toLocaleString('de-AT', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    return (
      <div className={'order-info__totals'}>
        <h4>Rechnungssumme</h4>
        <div className={'order-info__totals-row'}>
          <div>Rabatt</div>
          <div className={'order-info__totals-content'}>
            € {discount}
          </div>
        </div>
        <div className={'order-info__totals-row'}>
          <div>Liefermethode</div>
          <div className={'order-info__totals-content'}>
            {this.state.orderInfo.totals.shippingMethod}
          </div>
        </div>
        <div className={'order-info__totals-row'}>
          <div>Lieferung</div>
          <div className={'order-info__totals-content'}>
            € {shippingTotal}
          </div>
        </div>
        <div className={'order-info__totals-row'}>
          <div>Zahlungsmethode</div>
          <div className={'order-info__totals-content'}>
            {this.state.orderInfo.totals.paymentMethod}
          </div>
        </div>
        <div className={'order-info__totals-row'}>
          <div>Gesamtsumme</div>
          <div className={'order-info__totals-content'}>
            € {orderTotal}
            <div style={{ fontSize: '75%' , textAlign: 'right'}}>
              (inkl. € {totalTax} MwSt.)
            </div>
          </div>
        </div>
      </div>
    );
  }

  toggleInfoBox() {
    this.setState({
      infoBoxActive: !this.state.infoBoxActive,
    });
  }

  render() {
    if (this.state.error) {
      return <div>Error: {this.state.error.message}</div>;
    } else if (!this.state.isLoaded) {
      return <div>Loading</div>;
    }

    let infoBoxClassnames = 'order-info__info-box';
    if (!this.state.infoBoxActive) {
      infoBoxClassnames += ' hidden';
    }

    return (
      <div className={'order-detail__order-info'}>

        <header className={'order-info__header'} onClick={this.toggleInfoBox}>{this.getInfoBoxHeader()}</header>

        <div className={infoBoxClassnames}>
          <h2>Bestellungsdetails</h2>
          <div className={'order-info__info-box-content'}>
            {this.getShippingInfo()}
            {this.getTotals()}
          </div>
        </div>

      </div>
    );
  }
}
