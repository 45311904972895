import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import OrderOverview from './order-overview';
import OrderDetail from './order-detail';
import PrintOrder from './print-order';
import QrScanner from "./qr-scanner/qr-scanner";
import './scss/index.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Login from "./login";
import AuthenticationService from "./services/authentication-service";
import Loader from "./loader";

export default function App() {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  if (!userLoggedIn) {
    const localStorageToken = localStorage.getItem('authToken');
    if (localStorageToken) {
      let authenticationService = new AuthenticationService();
      authenticationService.authenticateToken(localStorageToken)
        .then(result => {
          console.log('USER REAUTHENTICATED!', result.data);
          setUserLoggedIn(true);
        })
        .catch(error => {
          authenticationService.deleteAuthenticationTokenFromLocalStorage();
          if (error.response) {
            console.warn(error.response.data);
          } else {
            console.error(error);
          }
        })

      return <Loader />;
    }

    return (
      <Login setUserLoggedIn={setUserLoggedIn}/>
    );
  }

  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route exact path="/">
          <OrderOverview/>
        </Route>
        <Route path="/print/order/:orderId">
          <PrintOrder/>
        </Route>
        <Route path="/order/:orderId">
          <OrderDetail/>
        </Route>
        <Route path="/scanner">
          <QrScanner/>
        </Route>
      </Switch>
    </Router>
  );
}

// ========================================

ReactDOM.render(
  <App/>,
  document.getElementById('root')
);
