import React from "react";
import OrderDetailSubcategory from "./order-detail-subcategory";

export default class OrderDetailRootCategory extends React.Component {

  constructor(props) {
    super(props);

    let subcategoriesPackedStatus = [];
    for (let i = 0; i < this.props.data.subcategories.length; i++) {
      subcategoriesPackedStatus[this.props.data.subcategories[i].slug] = false;
    }

    this.updateSubcategoryPackedStatus = this.updateSubcategoryPackedStatus.bind(this);

    this.state = {
      error: null,
      isLoaded: false,
      isPacked: false,
      category: null,
      categoryName: this.props.data.name,
      categorySlug: this.props.data.slug,
      subcategories: this.props.data.subcategories,
      subcategoriesPackedStatus: subcategoriesPackedStatus,
      orderID: props.orderID
    };
  }

  componentDidMount() {
    this.setState({
      isLoaded: true
    });
  }

  updateSubcategoryPackedStatus(subcategorySlug, isPacked) {
    let subcategoriesPackedStatus = this.state.subcategoriesPackedStatus;
    subcategoriesPackedStatus[subcategorySlug] = isPacked;

    this.setState({
      subcategoriesPackedStatus: subcategoriesPackedStatus
    });

    if (this.areAllSubcategoriesPacked()) {
      this.setState({
        isPacked: true
      });
    } else {
      this.setState({
        isPacked: false
      });
    }
  }

  areAllSubcategoriesPacked() {
    let isPacked = true;
    let subcategoriesPackedStatus = this.state.subcategoriesPackedStatus;

    for (let category in subcategoriesPackedStatus) {
      if (!subcategoriesPackedStatus[category]) {
        isPacked = false;
      }
    }
    return isPacked;
  }

  render() {
    let classnames = 'order-detail__root-category ' + this.props.data.slug;
    if (this.state.isPacked) {
      classnames += ' is-packed';
    }

    return (
      <div className={classnames}>
        <header className={'order-detail__root-category--header'}>
          <div className={'icon'}/>
          <h3>{this.props.data.name}</h3>
          <div className="category-item-count">Anzahl: {this.props.data.item_count}</div>
        </header>
        {this.props.data.subcategories.map((subcategory) => (
          <OrderDetailSubcategory key={subcategory.name} data={subcategory}
                                  changeIsPackedStatus={this.updateSubcategoryPackedStatus}
                                  orderID={this.state.orderID}/>
        ))}
      </div>
    );
  }

}
