import React from "react";
import AuthenticationService from "./services/authentication-service";
import Loader from "./loader";

class Login extends React.Component {
  authenticationService = new AuthenticationService();

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      userEmail: '',
      loggedIn: false,
      loading: false,
      isLoaded: false,
      error: null,
    }
  }

  onFormSubmit = (event) => {
    const setUserLoggedIn = this.props.setUserLoggedIn;

    event.preventDefault();
    const loginData = {
      username: this.state.username,
      password: this.state.password
    }

    this.setState(
      {loading: true},
      () => {
        this.authenticationService.retrieveAuthenticationToken(loginData)
          .then(result => {
            console.log(result.data);
            if (result.data.token === undefined) {
              this.setState({
                loading: false,
                error: true
              });
            }

            this.authenticationService.storeAuthenticationTokenInLocalStorage(result.data.token);

            this.setState({
              loading: false,
              authToken: result.data.token
            });
            // window.location.reload(true);
            setUserLoggedIn(true);
          })
          .catch(error => {
            if (error.response) {
              console.warn(error.response.data);
              this.setState({
                error: error.response.data,
                loading: false
              })

              this.authenticationService.deleteAuthenticationTokenFromLocalStorage();
            } else {
              console.error(error);
            }
          })
      }
    );
  }

  handleOnChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  getErrorMessage() {
    return (
      <div className="login__error-message"><b>Ein Fehler ist aufgetreten. Bitte probieren sie es
        erneut.</b><br/>Fehlermeldung: {this.state.error.message}
      </div>
    );
  }

  render() {
    const {username, password} = this.state;
    return (
      <div className="login">
        {this.state.loading && (
          <div className="loader-wrapper">
            <Loader/>
          </div>
        )}

        <form onSubmit={this.onFormSubmit}>

          {this.state.error && this.getErrorMessage()}

          <label className="form-group">
            Benutzername:
            <input
              type="text"
              autoComplete="username"
              className="form-control"
              name="username"
              value={username}
              onChange={this.handleOnChange}
            />
          </label>

          <br/>

          <label className="form-group">
            Passwort:
            <input
              type="password"
              autoComplete="current-password"
              className="form-control"
              name="password"
              value={password}
              onChange={this.handleOnChange}
            />
          </label>

          <br/>

          <button className="button" type="submit">Login</button>

        </form>
      </div>
    );
  }
}

export default Login;
