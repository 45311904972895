import React from "react";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import OrderService from "./services/order-service";
import Header from "./header";
import OrderDetailOrderInfo from "./order-detail-components/order-detail-order-info";
import OrderDetailProductTable from "./order-detail-components/order-detail-product-table";
import OrderDetailOrderNotices from "./order-detail-components/order-detail-order-notices";
import Login from "./login";

class OrderDetail
  extends React.Component {
  orderService = new OrderService();

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      orderData: null,
      order: null,
      orderId: this.props.match.params.orderId
    };
  }

  componentDidMount() {
    console.log('componentDidMount ' + this.props.match.params.orderId);
    this.refreshOrder();
    this.getOrderData();
  }

  get_order_meta_data(key) {
    for (let i = 0; i < this.state.order.meta_data.length; i++) {
      const meta_entry = this.state.order.meta_data[i];
      if (meta_entry['key'] === key) {
        return meta_entry['value'];
      }
    }
    return 'n/a';
  }

  get_is_packed(lineItem) {
    for (let i = 0; i < lineItem.meta_data.length; i++) {
      const meta_entry = lineItem.meta_data[i];
      if (meta_entry['key'] === '_fra_packed') {
        return meta_entry['value'] === '1';
      }
    }
    return false;
  }

  getOrderData() {
    this.orderService.getOrderDetailData(this.state.orderId).then(
      (result) => {
        console.log('component did mount: getOrderData');
        console.log(result);
        this.setState({
          isLoaded: true,
          orderData: result.data
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  refreshOrder() {
    this.orderService.getOrderData(this.state.orderId).then(
      (result) => {
        this.setState({
          isLoaded: true,
          order: result.data
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  setOrderStatus(status) {
    const data = {
      status: status
    };

    this.orderService.updateOrderStatus(this.state.orderId, data).then(
      (result) => {
        console.log(result);
        this.setState({
          isLoaded: true,
          order: result.data
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  updateOrderNotices() {
    this.refreshOrder()
  }

  render() {
    if (this.state.error) {
      return <div>Error: {this.state.error.message}<br/><Login/></div>;
    } else if (!this.state.isLoaded) {
      return (
        <div className="order-detail">
          <Header template={'order-detail'} title={'Bestellung #' + this.state.orderId}/>
          <br/><br/>
          <div>Bestellung #{this.state.orderId} wird geladen...</div>
        </div>
      );
    } else if (this.state.order && this.state.orderData) {
      return (
        <div className="order-detail">
          <Header template={'order-detail'} title={'Bestellung #' + this.state.orderId}/>

          <OrderDetailOrderInfo orderInfo={this.state.orderData.orderInfo} />
          <OrderDetailProductTable orderID={this.state.orderId}/>

          <OrderDetailOrderNotices
            orderId={this.state.orderId}
            orderNoticesData={this.state.orderData.orderNotices}
            customerNoteData={this.state.orderData.customerOrderNote}>
            updateOrderNotices={this.updateOrderNotices.bind.this}
          </OrderDetailOrderNotices>

          <footer className={'order-detail__footer'}>
            <Link to="/">
              <button>Zurück zur Bestellübersicht</button>
            </Link>

            <button
              style={{display: this.state.order.status === 'completed' ? 'block' : 'none'}}
              onClick={() => this.setOrderStatus('order-printed')}
            >Bestellung auf "order-printed" zurücksetzen
            </button>

            <button
              style={{display: this.state.order.status === 'order-printed' ? 'block' : 'none'}}
              onClick={() => this.setOrderStatus('completed')}
            >Bestellung abschließen
            </button>
          </footer>
        </div>
      );
    } else if (!this.state.order && this.state.orderData) {
      return (
        <div className="order-detail">
          <Header title={'Bestellung #' + this.state.orderId}/>
          <br/><br/>
          <div>Artikel für Bestellung #{this.state.orderId} werden geladen...</div>
          <OrderDetailOrderInfo orderInfo={this.state.orderData.orderInfo} />
        </div>
      );
    } else  {
      return (
        <div className="order-detail">
          <Header title={'Bestellung #' + this.state.orderId}/>
          <br/><br/>
          <div>Bestellung #{this.state.orderId} wird geladen...</div>
        </div>
      );
    }
  }
}

export default withRouter(OrderDetail);
