import React from "react";
import OrderService from "../services/order-service";

export default class OrderDetailOrderNotices extends React.Component {
  orderService = new OrderService();

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      orderId: props.orderId,
      orderNoticesData: props.orderNoticesData,
      orderCustomerNote: props.customerNoteData,
    };
  }

  componentDidMount() {
    this.setState({
      isLoaded: true
    })
  }

  addNewOrderNote() {
    debugger
    console.log('jetzt jaaa');
    this.orderService.addNewOrderNote(this.state.orderId, 'sadfasdfdsfsdf').then((response) => {
      console.log(response.data);
    })
      .catch((error) => {
        console.log(error.response.data);
      });

    this.props.updateOrderNotices();
  }

  render() {
    if (this.state.error) {
      return <div>Error: {this.state.error.message}</div>;
    } else if (!this.state.isLoaded) {
      return (
        'LOADING!!!!!'
      );
    } else {
      return (
        <div className={'order-detail__order-status-notes' + (this.state.isLoaded ? '' : ' is-loading')}>
          <h2>Bestell-Status</h2>
          <div className={'order-detail__order-notices'}>
            {
              this.state.orderNoticesData.map(orderNotice => {
                  return (
                    <div
                      className={'order-detail__order-notice' + (orderNotice.added_by === 'system' ? '' : ' highlighted')}
                      key={orderNotice.id}>
                      <div className={'order-detail__order-notice--header'}>{orderNotice.date_created.date.substr(0, 16)}
                        <div className={'added-by'}>Hinzugefügt von {orderNotice.added_by}</div>
                      </div>
                      <div className={'order-detail__order-notice--content'}>{orderNotice.content}</div>
                      <button onClick={this.addNewOrderNote.bind(this)}></button>
                    </div>
                  );
                }
              )
            }
          </div>
          {this.state.customerNoteData ? (
            <div className={'order-detail__customer-note'}>
              <h2>Kunden-Anmerkung</h2>
              {this.state.customerNoteData}
            </div>
          ) : ''}
        </div>
      );
    }
  }

}
