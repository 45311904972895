import {Font, StyleSheet} from "@react-pdf/renderer";
import fontOpenSans300 from "./fonts/open-sans-v27-latin-300.ttf";
import fontOpenSans400 from "./fonts/open-sans-v27-latin-regular.ttf";
import fontOpenSans500 from "./fonts/open-sans-v27-latin-500.ttf";
import fontOpenSans600 from "./fonts/open-sans-v27-latin-600.ttf";
import fontOpenSans800 from "./fonts/open-sans-v27-latin-800.ttf";
import fontOrpheusPro from "./fonts/orpheuspro.ttf";
import fontOrpheusProMedium from "./fonts/orpheuspro-medium.ttf";
import fontOrpheusProItalic from "./fonts/orpheuspro-italic.ttf";
import fontOrpheusProBold from "./fonts/orpheuspro-bold.ttf";

export default class PdfStyle {
  static styling = StyleSheet.create({
    page: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      backgroundColor: '#ffffff',
      fontSize: 10,
      padding: [10, 0, 20],
      fontFamily: 'OpenSans',
      fontWeight: 300,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      margin: [15, 15, 5],
      padding: 0,
      fontFamily: 'Josefin',
      border: '1px solid grey',
      fontSize: 14,
      fontWeight: 600
    },
    headerOrderID: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: [0, 10, 0],
      fontSize: 18,
      width: '12%',
    },
    headerShippingMethod: {
      margin: 5,
      padding: [5, 0, 2],
      width: '60%',
      alignSelf: 'center',
      textAlign: 'center',
      fontSize: '16px',
    },
    headerOrderDate: {
      margin: [11, 10, 9],
      width: '28%',
      height: '100%',
      fontSize: 18,
      textAlign: 'right',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      margin: [0],
      padding: [2, 5, 1],
    },
    addressColumn: {
      flex: 1,
      fontWeight: 400,
      fontSize: 9,
      lineHeight: 1.4,
      margin: [0, 10]
    },
    addressHeader: {
      fontFamily: 'OpenSans',
      fontSize: 10,
      fontWeight: 600,
      textTransform: 'uppercase',
      borderBottom: '1px solid #7d7d7d',
      lineHeight: 1,
      paddingBottom: 0,
      marginBottom: 5,
    },
    addressBlock: {
      padding: [5, 10],
    },
    itemCounts: {
      position: 'relative',
      backgroundColor: 'white',
      color: 'black',
    },
    itemCountColumn: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontFamily: 'OpenSans',
      textTransform: 'uppercase',
      lineHeight: '100%',
      padding: [4, 10, 2],
      fontWeight: 600,
      fontSize: 10
    },
    itemCountColumnNumber: {
      width: '25%',
      textAlign: 'right',
      fontFamily: 'OpenSans',
      fontWeight: 600,
      fontSize: '14px',
      marginRight: '50px',
    },
    rowCustomerNote: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: [5, 30],
      padding: [0, 5, 5],
      fontWeight: 600,
      width: 'auto',
      justifySelf: 'center',
      border: '2px solid #c1471c',
      borderLeft: '5px solid #c1471c',
    },
    customerNoteHeader: {
      fontFamily: 'OpenSans',
      fontSize: 10,
      lineHeight: 1,
      color: '#c1471c',
      fontWeight: 600,
      textTransform: 'uppercase',
      margin: 3,
    },
    customerNoteBody: {
      display: 'block',
      width: '100%',
      lineHeight: 1.2,
      margin: [0, 10],
    },
    categoryHeader: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      flex: 1,
      margin: [10, 5, 0],
      padding: [0, 5, 0, 2],
      color: 'black',
      fontFamily: 'OpenSans',
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    categoryRow: {
      display: 'block',
      width: '100%',
      position: 'relative',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      margin: [0, 10],
      padding: 0,
      flexWrap: 'wrap',
    },
    subcategoryColumn: {
      width: '22%',
      border: '1px solid #c1471c',
      margin: '5px, 1%',
    },
    subcategoryHeader: {
      margin: 0,
      padding: [2, 5, 1],
      backgroundColor: '#c1471c',
      color: 'white',
      fontFamily: 'OpenSans',
      fontSize: 10,
      fontWeight: 600,
    },
    subcategoryColumnContent: {
      fontSize: 10,
      lineHeight: 1.2,
      padding: [0, 0, 2]
    },
    subcategoryColumnSelektionspaketHeader: {
      display: 'block',
      margin: [2, 0, 0],
      padding: [0, 5, 2],
      backgroundColor: 'rgba(154, 163, 125, 0.5)',
      fontWeight: 500,
    },
    subcategoryColumnSelektionspaketPatisserieHeader: {
      margin: [2, 0, 2],
      padding: [0, 5, 2],
      backgroundColor: 'rgba(200, 178, 198, 0.5)',
      fontWeight: 500,
    },
    lineItemAmount: {
      fontWeight: 600,
    },
    totalsTable: {
      width: '100%',
      margin: '15px 5% 0',
      backgroundColor: 'white',
      border: '2px solid grey',
      borderBottom: '1px solid grey',
      fontSize: 11,
    },
    totalsTableHeader: {
      backgroundColor: 'white',
      color: 'black',
      textTransform: 'uppercase'
    },
    totalsTableRow: {
      borderBottom: '1px solid grey',
    },
    totalsTableRowItemCounts: {
      backgroundColor: 'rgba(0,0,0,0.06)',
    },
    totalsTableRowShippingMethod: {
      background: 'green',
    },
    totalsTableRowPaymentMethod: {
      background: 'green',
    },
    totalsTableColumnLeft: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '46%',
      fontFamily: 'OpenSans',
      fontWeight: 600,
      fontSize: 11,
      paddingTop: 1,
      paddingLeft: 15,
    },
    totalsTableColumnRight: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      width: '54%',
      textAlign: 'left',
      paddingRight: 15,
      fontWeight: 400,
    },
    greetingCardContainer: {
      position: 'absolute',
      fontFamily: 'Orpheus-Pro',
      color: '#c1471c',
      fontWeight: 400,
      fontSize: '14px'
    },
    greetingCardText: {
      width: 240,
      height: 240,
      top: 187,
      left: 73,
    },
    greetingCardRecipient: {
      width: 155,
      height: 50,
      top: 112,
      right: 60,
    },
    greetingCardSender: {
      width: 155,
      height: 50,
      top: 183,
      right: 60,
    },
    greetingCardAddress: {
      bottom: '1.5cm',
      right: '2cm',
      width: '9cm',
      height: '4.5cm',
      display: 'flex',
      justifyContent: 'center',
      padding: '0 1cm 0 2cm',
      fontSize: '12px'
    },
    greetingGardTextMedium: {
      fontWeight: 500,
      marginBottom: 5
    },
    greetingCardTitle: {
      fontSize: 26,
      position: 'absolute',
      top: -95,
      left: 0
    },
    selektionspakete: {
      backgroundColor: '#9aa37d',
    },
    broetchen: {
      backgroundColor: '#ffb98e',
    },
    patisserie: {
      backgroundColor: '#c8b2c6',
    },
    getraenke: {
      backgroundColor: '#a2b7cd',
    },
    geschenke: {
      backgroundColor: '#c1471c',
    },
    selektionspaketeCategoryHeader: {
      color: '#9aa37d',
      borderBottom: '2px solid #9aa37d',
    },
    broetchenCategoryHeader: {
      color: '#ffb98e',
      borderBottom: '2px solid #ffb98e',
    },
    patisserieCategoryHeader: {
      color: '#c8b2c6',
      borderBottom: '2px solid #c8b2c6',
    },
    getraenkeCategoryHeader: {
      color: '#a2b7cd',
      borderBottom: '2px solid #a2b7cd',
    },
    geschenkeCategoryHeader: {
      color: '#c1471c',
      borderBottom: '2px solid #c1471c',
    },
    borderselektionspakete: {
      borderColor: '#9aa37d'
    },
    borderbroetchen: {
      borderColor: '#ffb98e'
    },
    borderpatisserie: {
      borderColor: '#c8b2c6'
    },
    bordergetraenke: {
      borderColor: '#a2b7cd'
    },
    bordergeschenke: {
      borderColor: '#c1471c',
    },
    broetchenLight: {
      backgroundColor: 'rgba(244,183,152, 0.28)'
    },
    patisserieLight: {
      backgroundColor: 'rgba(196,179,197, 0.28)'
    },
    getraenkeLight: {
      backgroundColor: 'rgba(166,182,203, 0.28)'
    },
    geschenkeLight: {
      backgroundColor: 'rgba(193,71,28, 0.28)'
    },
  });
  static fonts = registerFonts();
}

function registerFonts() {
  Font.register({
    family: 'OpenSans', fonts: [
      {
        src: fontOpenSans300,
        fontWeight: 300
      },
      {
        src: fontOpenSans400,
        fontWeight: 400
      },
      {
        src: fontOpenSans500,
        fontWeight: 500
      },
      {
        src: fontOpenSans600,
        fontWeight: 600
      },
      {
        src: fontOpenSans800,
        fontWeight: 800
      },
    ]
  })

  Font.register({
    family: 'Josefin', fonts: [
      {
        src: 'https://fonts.gstatic.com/s/josefinsans/v17/Qw3aZQNVED7rKGKxtqIqX5EUDXx4Vn8sig.woff2',
        fontWeight: 300
      },
      {
        src: 'https://fonts.gstatic.com/s/josefinsans/v17/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_GbQbMZhKSbpUVzEEQ.woff',
        fontWeight: 400
      },
      {
        src: 'https://fonts.gstatic.com/s/josefinsans/v17/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTtIJRLSzuc1jcEIK2.woff',
        fontStyle: 'italic',
        fontWeight: 400
      },
      {
        src: 'https://fonts.gstatic.com/s/josefinsans/v17/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ObXbMZhKSbpUVzEEQ.woff',
        fontStyle: 'normal',
        fontWeight: 600
      },
    ]
  });

  Font.register({
    family: 'Orpheus-Pro', fonts: [
      {
        src: fontOrpheusPro,
        fontWeight: 400,
      },
      {
        src: fontOrpheusProMedium,
        fontWeight: 500,
      },
      {
        src: fontOrpheusProItalic,
        fontStyle: 'italic'
      },
      {
        src: fontOrpheusProBold,
        fontWeight: 700
      }
    ]
  })
}

