import React from "react";
import {withRouter} from "react-router";
import OrderService from "./services/order-service";
import Loader from "./loader";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
} from '@react-pdf/renderer';
import NumberFormat from 'react-number-format';
import Login from "./login";
import PdfStyle from "./pdf-styling"
import PdfImages from "./pdf-images";

let pdfWasLoaded = false;
export const onRenderDocument = (blob, fileName) => {
  var blobUrl = URL.createObjectURL(blob.blob);
  if (!pdfWasLoaded) {
    saveDocument(blobUrl, fileName);
    pdfWasLoaded = true;
  }
};

export const saveDocument = (function () {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  return function (blob, fileName) {
    a.href = blob;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(blob);
  };
}());

class PrintOrder
  extends React.Component {
  orderService = new OrderService();

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      orderData: null,
      orderId: this.props.match.params.orderId,
      styling: null,
      customColorCoding: {
        shippingMethod: {backgroundColor: 'transparent'},
        paymentMethod: {backgroundColor: 'transparent'},
      },
    };
  }

  componentDidMount() {
    console.log('componentDidMount ' + this.props.match.params.orderId);
    this.setState({
      styling: PdfStyle.styling,
    });
    this.getOrderData();
  }

  getCustomColorCoding() {
    let shippingMethod = this.state.orderData.orderInfo.totals.shippingMethod;
    let shippingMethodStyling = {backgroundColor: 'transparent', color: 'black'}

    if (shippingMethod.includes('Zustellung') || shippingMethod.includes('Kostenlose Lieferung')) {
      shippingMethodStyling = {backgroundColor: '#9aa37d', color: 'white'};
    } else if (shippingMethod.includes('Sorger Wiener Straße')) {
      shippingMethodStyling = {backgroundColor: 'sienna', color: 'white'};
    } else if (shippingMethod.includes('Sorger Waltendorf')) {
      shippingMethodStyling = {backgroundColor: 'steelblue', color: 'white'};
    } else if (shippingMethod.includes('Abholung')) {
      shippingMethodStyling = {backgroundColor: 'white', color: 'black'};
    }

    let paymentMethod = this.state.orderData.orderInfo.totals.paymentMethod;
    let paymentMethodStyling = {backgroundColor: '#a2b7cd', color: 'black'}

    if (paymentMethod.includes('Abholung')) {
      paymentMethodStyling = {backgroundColor: '#c1471c', color: 'black'};
    }

    this.setState({
      isLoaded: true,
      customColorCoding: {
        shippingMethod: shippingMethodStyling,
        paymentMethod: paymentMethodStyling,
      },
    });
  }

  getOrderData() {
    this.orderService.getOrderDetailData(this.state.orderId).then(
      (result) => {
        console.log('component did mount: getOrderData');
        console.log(result);
        this.setState({
          orderData: result.data
        }, () => {
          this.getCustomColorCoding();
          if (this.state.orderData.orderInfo.status === 'processing') {
            this.setOrderStatusToOrderPrinted();
          }
        });
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  setOrderStatusToOrderPrinted() {
    const data = {
      status: 'order-printed',
    };

    this.orderService.updateOrderStatus(this.state.orderId, data).then(
      (result) => {
        console.log(result);
      },
      (error) => {
        console.warn(error)
      }
    )
  }

  render() {
    if (this.state.error) {
      return <div>Error: {this.state.error.message} <br/><Login/></div>;
    } else if (!this.state.isLoaded) {
      return (
        <div className={'order-pdf is-loading'}>
          <h1>Druckansicht für Bestellung #{this.state.orderId} wird geladen</h1>
          <Loader/>
        </div>
      );
    } else {
      const containsGreetingCard = this.state.orderData.containsCustomerGreetingCard === 'on';
      return (
        <div className={'order-pdf'}>
          <PDFViewer className={'pdf-viewer'}>
            <Document title={this.state.orderId} subject={this.state.orderId}
                      onRender={(blob) => onRenderDocument(blob, this.props.match.params.orderId + '.pdf')}>
              <Page size="A4" style={[this.state.styling.page]}>

                {PdfImages.getFrankowitschLogoSvg()}

                {/* HEADER */}
                <View style={[this.state.styling.row, this.state.styling.header]} fixed>
                  <View style={[this.state.styling.headerOrderID]}>
                    <Text style={{fontSize: 8, lineHeight: 1.1, display: 'block'}}>Bestellung No.:</Text>
                    <Text style={{display: 'block'}}>{this.state.orderId}</Text>
                  </View>

                  {/* add Sorger-logo for Sorger-orders */}
                  {this.state.orderData.orderInfo.totals.shippingMethod.includes('Sorger') && (
                    <View
                      style={[this.state.customColorCoding.shippingMethod, this.state.styling.headerShippingMethod, {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }]}>
                      <Text>Abholung</Text>
                      {PdfImages.getSorgerLogoSvg()}
                      <Text>{this.state.orderData.orderInfo.totals.shippingMethod.replace('Abholung Sorger ', '')}</Text>
                    </View>
                  )}
                  {!this.state.orderData.orderInfo.totals.shippingMethod.includes('Sorger') && (
                    <Text
                      style={[this.state.customColorCoding.shippingMethod, this.state.styling.headerShippingMethod]}>
                      {this.state.orderData.orderInfo.totals.shippingMethod}
                    </Text>
                  )}

                  <Text style={[this.state.styling.headerOrderDate]}>
                    {this.state.orderData.orderInfo.orderDate} <Text
                    style={{fontWeight: 400, color: 'grey'}}>|</Text> {this.state.orderData.orderInfo.orderTime}
                  </Text>
                </View>

                {/* ADRESSES */}
                <View style={[this.state.styling.row, {marginBottom: 5}]}>

                  {/* BILLING ADDRESS */}
                  <View style={[this.state.styling.addressColumn, this.state.styling.addressBlock]}>
                    <Text style={this.state.styling.addressHeader}>Rechnungsadresse</Text>
                    <Text>
                      {this.state.orderData.orderInfo.billingAddress.first_name}&nbsp;
                      {this.state.orderData.orderInfo.billingAddress.last_name}
                    </Text>
                    {this.state.orderData.orderInfo.billingAddress.company !== '' && (
                      <Text>{this.state.orderData.orderInfo.billingAddress.company}</Text>
                    )}
                    <Text>{this.state.orderData.orderInfo.billingAddress.address_1}</Text>
                    <Text>
                      {this.state.orderData.orderInfo.billingAddress.postcode}&nbsp;
                      {this.state.orderData.orderInfo.billingAddress.city}
                    </Text>
                    {this.state.orderData.orderInfo.billingAddress.phone !== '' && (
                      <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 5}}>
                        <View style={{marginRight: 5}}>{PdfImages.getSvgIcon('phone')}</View>
                        <View><Text>{this.state.orderData.orderInfo.billingAddress.phone}</Text></View>
                      </View>
                    )}
                    {this.state.orderData.orderInfo.billingAddress.email !== '' && (
                      <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{marginRight: 5}}>{PdfImages.getSvgIcon('mail')}</View>
                        <View><Text>{this.state.orderData.orderInfo.billingAddress.email}</Text></View>
                      </View>
                    )}
                  </View>

                  {/* SHIPPING ADDRESS */}
                  {
                    this.state.orderData.orderInfo.totals.shippingMethod !== 'Abholung' &&
                    (
                      <View style={[this.state.styling.addressColumn, this.state.styling.addressBlock]}>
                        <Text style={this.state.styling.addressHeader}>Lieferadresse</Text>
                        <Text>
                          {this.state.orderData.orderInfo.shippingAddress.first_name}&nbsp;
                          {this.state.orderData.orderInfo.shippingAddress.last_name}
                        </Text>
                        {this.state.orderData.orderInfo.shippingAddress.company !== '' && (
                          <Text>{this.state.orderData.orderInfo.shippingAddress.company}</Text>
                        )}
                        <Text>{this.state.orderData.orderInfo.shippingAddress.address_1}</Text>
                        <Text>
                          {this.state.orderData.orderInfo.shippingAddress.postcode}&nbsp;
                          {this.state.orderData.orderInfo.shippingAddress.city}
                        </Text>
                      </View>
                    )
                  }

                  {/* ITEM COUNTS */}
                  <View style={[this.state.styling.addressColumn, this.state.styling.itemCounts]}>
                    {this.state.orderData.categoryItemCounts.selection_packages !== 0 && (
                      <View
                        style={[this.state.styling.itemCountColumn, this.state.styling.selektionspakete]}>
                        <View>
                          <Text>Pakete: </Text>
                        </View>
                        <View style={this.state.styling.itemCountColumnNumber}>
                          <Text>{this.state.orderData.categoryItemCounts.selection_packages}</Text>
                        </View>
                      </View>
                    )}
                    {this.state.orderData.categoryItemCounts.sandwiches !== 0 && (
                      <View
                        style={[this.state.styling.itemCountColumn, this.state.styling.broetchen]}>
                        <View style={{display: 'inline-block', width: '75%'}}>
                          <Text>Brötchen: </Text>
                        </View>
                        <View style={this.state.styling.itemCountColumnNumber}>
                          <Text>{this.state.orderData.categoryItemCounts.sandwiches}</Text>
                        </View>
                      </View>
                    )}
                    {this.state.orderData.categoryItemCounts.desserts !== 0 && (
                      <View
                        style={[this.state.styling.itemCountColumn, this.state.styling.patisserie]}>
                        <View style={{display: 'inline-block', width: '75%'}}>
                          <Text>Desserts: </Text>
                        </View>
                        <View style={this.state.styling.itemCountColumnNumber}>
                          <Text>{this.state.orderData.categoryItemCounts.desserts}</Text>
                        </View>
                      </View>
                    )}
                    {this.state.orderData.categoryItemCounts.drinks !== 0 && (
                      <View
                        style={[this.state.styling.itemCountColumn, this.state.styling.getraenke]}>
                        <View style={{display: 'inline-block', width: '75%'}}>
                          <Text>Getränke: </Text>
                        </View>
                        <View style={this.state.styling.itemCountColumnNumber}>
                          <Text>{this.state.orderData.categoryItemCounts.drinks}</Text>
                        </View>
                      </View>
                    )}
                    {this.state.orderData.categoryItemCounts.gift_packages !== 0 && (
                      <View
                        style={[this.state.styling.itemCountColumn, this.state.styling.geschenke]}>
                        <View style={{display: 'inline-block', width: '75%'}}>
                          <Text>Geschenke: </Text>
                        </View>
                        <View style={this.state.styling.itemCountColumnNumber}>
                          <Text>{this.state.orderData.categoryItemCounts.gift_packages}</Text>
                        </View>
                      </View>
                    )}
                  </View>

                </View>
                {/* End addresses */}

                {/* GREETING CARD */}
                {
                  this.state.orderData.containsCustomerGreetingCard === 'on' && (
                    <View style={[this.state.styling.row, this.state.styling.rowCustomerNote]}>
                      <Text style={[this.state.styling.customerNoteHeader]}>GESCHENK: </Text>
                      <Text style={[this.state.styling.customerNoteBody, {color: 'red'}]}>
                        nur die Karte mitgeben und Bestellschein weggeben
                      </Text>
                    </View>
                  )
                }
                {/* End greeting card */}

                {/* CUSTOMER NOTE */}
                {
                  this.state.orderData.customerOrderNote !== '' && (
                    <View style={[this.state.styling.row, this.state.styling.rowCustomerNote]}>
                      <Text style={[this.state.styling.customerNoteHeader]}>Kunden-Anmerkung: </Text>
                      <Text style={[this.state.styling.customerNoteBody]}>
                        {this.state.orderData.customerOrderNote}
                      </Text>
                    </View>
                  )
                }
                {/* End customer note */}

                {/* CATEGORIES */}
                {this.state.orderData.sortedItems.map(category => {
                  // "Verpackung in Einwegtassen od Metalltassen"
                  let hasPackaging = this.state.orderData.orderInfo.totals.packagingMethod !== '';
                  let hasSaltySelektionspakete = false;
                  let saltySelektionspakete = ['Fisch', 'Fleisch', 'Klassiker', 'Festliches', 'Veggi Box', 'Vegane Box']

                  if (category.slug === 'selektionspakete') {
                    category.subcategories.map(subcategory => {
                      if (saltySelektionspakete.includes(subcategory.name)) {
                        hasSaltySelektionspakete = true;
                        return '';
                      }
                      return '';
                    });
                  }

                  return (
                    <View key={category.slug} style={{width: '100%', paddingLeft: '10px'}} wrap={false}>

                      {/* CATEGORY HEADER */}
                      <View style={{
                        margin: [0, 10, 0],
                      }}>
                        <View
                          style={[this.state.styling.categoryHeader, this.state.styling[category.slug + 'CategoryHeader']]}>

                          <View style={{marginTop: '-5px'}}>{PdfImages.getCategorySvg(category.slug)}</View>

                          <Text style={{lineHeight: 1, marginLeft: 8}}>{category.name}</Text>
                          {(category.slug === 'broetchen' || (category.slug === 'selektionspakete' && hasSaltySelektionspakete)) &&
                            hasPackaging &&
                            <Text style={{
                              backgroundColor: this.state.orderData.orderInfo.totals.packagingMethod === 'Metalltassen' ? '#ffef00' : '#FF6701',
                              color: 'black',
                              fontFamily: 'OpenSans',
                              fontSize: 12,
                              fontWeight: 800,
                              margin: [-3, 0, 0, 15],
                              padding: [1, 5, 2],
                              lineHeight: 1.1,
                              textTransform: 'uppercase',
                            }}>
                              {category.slug === 'selektionspakete' && 'Brötchen '}
                              in {this.state.orderData.orderInfo.totals.packagingMethod}
                            </Text>
                          }
                        </View>
                      </View>

                      <View style={[this.state.styling.row, this.state.styling.categoryRow]}>
                        {/* SUBCATEGORY */}
                        {
                          category.subcategories.map(subcategory => {
                            let subcategory_border_styling = this.state.styling['border' + category.slug];
                            let subcategory_background_styling = this.state.styling[category.slug];

                            return (
                              <View key={subcategory.slug}
                                    style={[this.state.styling.subcategoryColumn, subcategory_border_styling]}>

                                {/* SUBCATEGORY HEADER */}
                                <Text
                                  style={[this.state.styling.subcategoryHeader, subcategory_background_styling]}>
                                  {subcategory.name}
                                </Text>

                                {/* SUBCATEGORY-ITEMS */}
                                <View style={[this.state.styling.subcategoryColumnContent]}>
                                  {
                                    subcategory.line_items.map(lineItem => {
                                      let customLineItemStyling = {
                                        margin: [2, 5, 2]
                                      };
                                      let lineItemName = lineItem.name;
                                      if (lineItem.name.includes('Selektionspaket')) {
                                        customLineItemStyling = this.state.styling.subcategoryColumnSelektionspaketHeader;
                                        lineItemName = lineItem.name.replace('Selektionspaket ', '')
                                      } else if (lineItem.name.includes('paket') || lineItem.name.includes('Plunder 5 Stk')) {
                                        customLineItemStyling = this.state.styling.subcategoryColumnSelektionspaketPatisserieHeader;
                                        lineItemName = lineItemName.replace('paket', '')
                                      } else if (subcategory.slug === 'glutenfreie-broetchen'
                                        || subcategory.slug === 'selektions-pakete-glutenfrei') {
                                        customLineItemStyling = {
                                          margin: [2, 5, 2],
                                          backgroundColor: '#ff904d',
                                        }
                                      }

                                      return (
                                        <View style={[customLineItemStyling, {
                                          display: 'flex',
                                          flexDirection: 'row',
                                          flexWrap: 'nowrap',
                                        }]}
                                              key={lineItem.id}>
                                          <View style={this.state.styling.lineItemAmount}>
                                            <Text>{lineItem.quantity}x</Text>
                                          </View>
                                          <View style={{flex: 1, marginLeft: 5}}><Text>{lineItemName}</Text></View>
                                        </View>
                                      )
                                    })
                                  }
                                </View>
                                {/* End subcategory-items */}

                              </View>
                            )
                          })
                        }
                        {/* End subcategory */}
                      </View>

                    </View>
                  )
                })}
                {/* End category */}


                {/* TOTALS TABLE */}
                <View style={this.state.styling.totalsTable} wrap={false}>

                  {/* TOTALS Category item counts */}
                  {/* TOTALS Brötchen item count */}
                  {
                    this.state.orderData.categoryTotals['sandwiches'] > 0 && (
                      <View
                        style={[this.state.styling.row, this.state.styling.totalsTableRow, this.state.styling.broetchenLight]}>
                        <View style={[this.state.styling.totalsTableColumnLeft]}>
                          <Text>Brötchen</Text>
                        </View>
                        <View style={[this.state.styling.totalsTableColumnRight]}>
                          <Text style={[this.state.styling.totalsTableTotalsNumber]}>
                            <NumberFormat
                              value={this.state.orderData.categoryTotals['sandwiches']}
                              isNumericString={true}
                              displayType={'text'}
                              prefix={'€ '}
                              decimalSeparator={','}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </Text>
                        </View>
                      </View>
                    )
                  }

                  {/* TOTALS Patisserie item count */}
                  {
                    this.state.orderData.categoryTotals['desserts'] > 0 && (
                      <View
                        style={[this.state.styling.row, this.state.styling.totalsTableRow, this.state.styling.patisserieLight]}>
                        <View style={[this.state.styling.totalsTableColumnLeft]}>
                          <Text>Desserts</Text>
                        </View>
                        <View style={[this.state.styling.totalsTableColumnRight]}>
                          <Text style={[this.state.styling.totalsTableTotalsNumber]}>
                            <NumberFormat
                              value={this.state.orderData.categoryTotals['desserts']}
                              isNumericString={true}
                              displayType={'text'}
                              prefix={'€ '}
                              decimalSeparator={','}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </Text>
                        </View>
                      </View>
                    )
                  }

                  {/* TOTALS Getränke item count */}
                  {
                    this.state.orderData.categoryTotals['drinks'] > 0 && (
                      <View
                        style={[this.state.styling.row, this.state.styling.totalsTableRow, this.state.styling.getraenkeLight]}>
                        <View style={[this.state.styling.totalsTableColumnLeft]}>
                          <Text>Getränke</Text>
                        </View>
                        <View style={[this.state.styling.totalsTableColumnRight]}>
                          <Text style={[this.state.styling.totalsTableTotalsNumber]}>
                            <NumberFormat
                              value={this.state.orderData.categoryTotals['drinks']}
                              isNumericString={true}
                              displayType={'text'}
                              prefix={'€ '}
                              decimalSeparator={','}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </Text>
                        </View>
                      </View>
                    )
                  }

                  {/* TOTALS Geschenke item count */}
                  {
                    this.state.orderData.categoryTotals['gifts_20'] > 0 && (
                      <View
                        style={[this.state.styling.row, this.state.styling.totalsTableRow, this.state.styling.geschenkeLight]}>
                        <View style={[this.state.styling.totalsTableColumnLeft]}>
                          <Text>Geschenke</Text>
                          <Text style={{fontWeight: 400}}>&nbsp;(Getränke)</Text>
                        </View>
                        <View style={[this.state.styling.totalsTableColumnRight]}>
                          <Text style={[this.state.styling.totalsTableTotalsNumber]}>
                            <NumberFormat
                              value={this.state.orderData.categoryTotals['gifts_20']}
                              isNumericString={true}
                              displayType={'text'}
                              prefix={'€ '}
                              decimalSeparator={','}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </Text>
                        </View>
                      </View>
                    )
                  }
                  {
                    this.state.orderData.categoryTotals['gifts_10'] > 0 && (
                      <View
                        style={[this.state.styling.row, this.state.styling.totalsTableRow, this.state.styling.geschenkeLight]}>
                        <View style={[this.state.styling.totalsTableColumnLeft]}>
                          <Text>Geschenke</Text>
                          <Text style={{fontWeight: 400}}>&nbsp;(Lebensmittel)</Text>
                        </View>
                        <View style={[this.state.styling.totalsTableColumnRight]}>
                          <Text style={[this.state.styling.totalsTableTotalsNumber]}>
                            <NumberFormat
                              value={this.state.orderData.categoryTotals['gifts_10']}
                              isNumericString={true}
                              displayType={'text'}
                              prefix={'€ '}
                              decimalSeparator={','}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </Text>
                        </View>
                      </View>
                    )
                  }


                  {/* TOTALS Discount */}
                  {this.state.orderData.orderInfo.totals.discount !== 0 && (
                    <View style={[this.state.styling.row, this.state.styling.totalsTableRow]}>
                      <View style={[this.state.styling.totalsTableColumnLeft]}><Text>Online-Rabatt:</Text></View>
                      <View style={[this.state.styling.totalsTableColumnRight]}>
                        <Text style={[this.state.styling.totalsTableTotalsNumber]}>
                          <NumberFormat
                            value={this.state.orderData.orderInfo.totals.discount}
                            isNumericString={true}
                            displayType={'text'}
                            prefix={'- € '}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </Text>
                      </View>
                    </View>
                  )}

                  {/* GREETING CARD */}
                  {this.state.orderData.orderInfo.totals.greetingCardFee !== 0 && (
                    <View
                      style={[this.state.styling.row, this.state.styling.totalsTableRow, {
                        backgroundColor: 'red',
                        color: 'white'
                      }]}>
                      <View style={[this.state.styling.totalsTableColumnLeft]}>
                        <Text>Persönliche Grußbotschaft:</Text>
                      </View>
                      <View style={[this.state.styling.totalsTableColumnRight]}>
                        {this.state.orderData.orderInfo.totals.greetingCardFee !== 0 && (
                          <>
                            <Text style={[this.state.styling.totalsTableTotalsNumber, {marginRight: '15px'}]}>
                              <NumberFormat
                                value={this.state.orderData.orderInfo.totals.greetingCardFee}
                                isNumericString={true}
                                displayType={'text'}
                                prefix={'€ '}
                                decimalSeparator={','}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </Text>
                            <Text style={{fontSize: 8, paddingBottom: 2}}>inkl.
                              <NumberFormat
                                value={this.state.orderData.orderInfo.totals.greetingCardFeeTax}
                                isNumericString={true}
                                displayType={'text'}
                                prefix={'€ '}
                                decimalSeparator={','}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                              &nbsp;MwSt.
                            </Text>
                          </>
                        )}
                      </View>
                    </View>
                  )}

                  {/* TOTALS Coupon-discounts */}
                  {this.state.orderData.orderInfo.totals.couponDiscounts !== 0 && (
                    <View style={[this.state.styling.row, this.state.styling.totalsTableRow]}>
                      <View style={[this.state.styling.totalsTableColumnLeft]}><Text>Gutschein-Rabatt:</Text></View>
                      <View style={[this.state.styling.totalsTableColumnRight]}>
                        <Text style={[this.state.styling.totalsTableTotalsNumber]}>
                          <NumberFormat
                            value={this.state.orderData.orderInfo.totals.couponDiscounts}
                            isNumericString={true}
                            displayType={'text'}
                            prefix={'- € '}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </Text>
                      </View>
                    </View>
                  )}

                  {/* TOTALS Packaging + Tax */}
                  {this.state.orderData.orderInfo.totals.packagingMethod !== '' && (
                    <View
                      style={[this.state.styling.row, this.state.styling.totalsTableRow, {
                        backgroundColor: this.state.orderData.orderInfo.totals.packagingMethod === 'Metalltassen' ? '#ffef00' : '#FF6701',
                        color: 'black'
                      }]}>
                      <View style={[this.state.styling.totalsTableColumnLeft]}>
                        <Text>Verpackung in {this.state.orderData.orderInfo.totals.packagingMethod}:</Text>
                      </View>
                      <View style={[this.state.styling.totalsTableColumnRight]}>
                        {this.state.orderData.orderInfo.totals.packagingFee === 0 && (
                          <Text>kostenlos</Text>
                        )}
                        {this.state.orderData.orderInfo.totals.packagingFee !== 0 && (
                          <>
                            <Text style={[this.state.styling.totalsTableTotalsNumber, {marginRight: '15px'}]}>
                              <NumberFormat
                                value={this.state.orderData.orderInfo.totals.packagingFee}
                                isNumericString={true}
                                displayType={'text'}
                                prefix={'€ '}
                                decimalSeparator={','}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </Text>
                            <Text style={{fontSize: 8, paddingBottom: 2}}>inkl.
                              <NumberFormat
                                value={this.state.orderData.orderInfo.totals.packagingFeeTax}
                                isNumericString={true}
                                displayType={'text'}
                                prefix={'€ '}
                                decimalSeparator={','}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                              &nbsp;MwSt.
                            </Text>
                          </>
                        )}
                      </View>
                    </View>
                  )}

                  {/* TOTALS Shipping */}
                  <View
                    style={[this.state.styling.row, this.state.styling.totalsTableRow, this.state.styling.totalsTableRowShippingMethod]}>
                    <View style={[this.state.styling.totalsTableColumnLeft]}>
                      <Text>{this.state.orderData.orderInfo.totals.shippingMethod}</Text>
                    </View>
                    <View style={[this.state.styling.totalsTableColumnRight]}>
                      {this.state.orderData.orderInfo.totals.shippingTotal === '0' && (
                        <Text>kostenlos</Text>
                      )}

                      {this.state.orderData.orderInfo.totals.shippingTotal !== '0' && (
                        <Text style={[this.state.styling.totalsTableTotalsNumber]}>
                          <NumberFormat
                            value={parseFloat(this.state.orderData.orderInfo.totals.shippingTotal) + parseFloat(this.state.orderData.orderInfo.totals.shippingTax)}
                            isNumericString={true}
                            displayType={'text'}
                            prefix={'€ '}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </Text>
                      )}
                    </View>
                  </View>

                  {/* TOTALS Payment method */}
                  <View
                    style={[this.state.styling.row, this.state.styling.totalsTableRow, this.state.styling.totalsTableRowPaymentMethod, this.state.customColorCoding.paymentMethod]}>
                    <View style={[this.state.styling.totalsTableColumnLeft]}><Text>Zahlungsmethode:</Text></View>
                    <View style={[this.state.styling.totalsTableColumnRight]}>
                      <Text>{this.state.orderData.orderInfo.totals.paymentMethod}</Text>
                    </View>
                  </View>

                  {/* TOTALS Total + tax */}
                  <View style={[this.state.styling.row, this.state.styling.totalsTableRow]}>
                    <View style={[this.state.styling.totalsTableColumnLeft]}><Text>Gesamtbetrag:</Text></View>
                    <View style={[this.state.styling.totalsTableColumnRight]}>
                      <Text style={[this.state.styling.totalsTableTotalsNumber, {
                        fontFamily: 'OpenSans',
                        fontWeight: 600,
                        marginRight: '15px'
                      }]}>
                        <NumberFormat
                          value={this.state.orderData.orderInfo.totals.total}
                          isNumericString={true}
                          displayType={'text'}
                          prefix={'€ '}
                          decimalSeparator={','}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </Text>
                      <Text style={{fontSize: 8, paddingBottom: 2}}>inkl.
                        {/* Don't show (wrong) tax when coupn-discounts are applied */}
                        {this.state.orderData.orderInfo.totals.couponDiscounts === 0 &&
                          <NumberFormat
                            value={this.state.orderData.orderInfo.totals.totalTax}
                            isNumericString={true}
                            displayType={'text'}
                            prefix={'€ '}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        }
                        &nbsp;MwSt.
                      </Text>
                    </View>
                  </View>

                </View>
                {/* End totals table */}

                {/* FOOTER */}
                <View style={{
                  display: 'flex',
                  margin: [0, 30],
                  position: 'absolute',
                  bottom: '20px',
                  color: 'grey',
                  width: '100%',
                }} fixed>
                  <Text style={{fontSize: 8}}>Bestellt bei Frankowitsch - delikate Angelegenheiten</Text>
                </View>
                <View style={{
                  display: 'flex',
                  margin: [0, 30],
                  position: 'absolute',
                  bottom: '20px',
                  right: '10px',
                  color: 'grey',
                  fontSize: 9,
                  width: '200px',
                  textAlign: 'right',
                }} fixed>
                  <Text render={({pageNumber, totalPages}) => (
                    `Seite: ${pageNumber} / ${totalPages}`
                  )}/>
                </View>

              </Page>

              {containsGreetingCard &&
                <Page containsGreetingCard={false} size="A4"
                      style={[this.state.styling.page, {padding: 0, paddingTop: 7}]}>
                  {PdfImages.getBackgroundImage()}
                  <View style={{
                    height: '34%',
                    width: '100%',
                    paddingTop: '30px',
                  }}>
                    <View
                      style={[this.state.styling.greetingCardContainer, this.state.styling.greetingCardAddress]}>
                      <Text>
                        {this.state.orderData.orderInfo.shippingAddress.first_name}&nbsp;
                        {this.state.orderData.orderInfo.shippingAddress.last_name}
                      </Text>
                      <Text>
                        {this.state.orderData.orderInfo.shippingAddress.address_1}
                      </Text>
                      <Text>
                        {this.state.orderData.orderInfo.shippingAddress.postcode}&nbsp;
                        {this.state.orderData.orderInfo.shippingAddress.city}
                      </Text>
                    </View>
                  </View>
                  <View style={{
                    height: '66%',
                    width: '100%',
                    position: 'relative'
                  }}>
                    {/* GREETING CARD */}
                    {
                      this.state.orderData.containsCustomerGreetingCard === 'on' && (
                        <View
                          style={[this.state.styling.greetingCardContainer, this.state.styling.greetingCardText]}>
                          <Text style={[this.state.styling.greetingGardTextMedium, this.state.styling.greetingCardTitle]}>
                            Genussvolle Grüße
                          </Text>
                        </View>
                      )
                    }
                    {
                      this.state.orderData.containsCustomerGreetingCard === 'on' && (
                        <View
                          style={[this.state.styling.greetingCardContainer, this.state.styling.greetingCardText]}>
                          <Text>
                            {this.state.orderData.greetingCardText}
                          </Text>
                        </View>
                      )
                    }
                    {
                      this.state.orderData.containsCustomerGreetingCard === 'on' && (
                        <View
                          style={[this.state.styling.greetingCardContainer, this.state.styling.greetingCardRecipient]}>
                          <Text style={this.state.styling.greetingGardTextMedium}>
                            für
                          </Text>
                          <Text>
                            {this.state.orderData.greetingCardRecipient}
                          </Text>
                        </View>
                      )
                    }
                    {
                      this.state.orderData.containsCustomerGreetingCard === 'on' && (
                        <View
                          style={[this.state.styling.greetingCardContainer, this.state.styling.greetingCardSender]}>
                          <Text style={this.state.styling.greetingGardTextMedium}>
                            von
                          </Text>
                          <Text>
                            {this.state.orderData.greetingCardSender}
                          </Text>
                        </View>
                      )
                    }
                    {/* End greeting card */}
                  </View>
                </Page>
              }
            </Document>
          </PDFViewer>
        </div>
      );
    }
  }
}

export default withRouter(PrintOrder);

