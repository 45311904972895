import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import axios from 'axios' ;

class OrderService {
  constructor() {
    console.log('Starting order service... location is: ' + window.location.hostname);
    console.log('Verifying user...');
    if (!localStorage.getItem('authToken')) {
      console.warn('User not logged in!');
      return false;
    } else {
      this.authToken = localStorage.getItem('authToken');
    }

    let api_url;
    switch(window.location.hostname) {
      case 'localhost':
        api_url = 'https://frankowitsch.test';
        break;
      case 'test-lieferschein.frankowitsch.at':
        api_url = 'https://test.frankowitsch.at';
        break;
      default:
        api_url = 'https://frankowitsch.at';
    }
    console.log('API-url is: ' + api_url);

    this.api = new WooCommerceRestApi({
      url: api_url,
      consumerKey: "ck_36f4b8867efe36b35cd9a06a3e7bb88cf28a721d",
      consumerSecret: "cs_c8e29114d776aa86188676864c74856476e4ec51" +
        "",
      version: "wc/v3",
    });


    // avoid a warning
    axios.interceptors.request.use(function (config) {
      const {headers = {}} = config || {};
      if (headers['User-Agent']) {
        delete config.headers['User-Agent'];
      }

      if (localStorage.getItem('authToken')) {
        config.headers['authorization'] = 'Bearer ' + localStorage.getItem('authToken');
      }

      return config;
    })
  }

  retrieveAllOrders(selectedDate) {
    return this.api.get( 'fra_orders/' + selectedDate);
  }

  getOrderDetailData(orderID) {
    return this.api.get('fra_order_details/' + orderID);
  }

  getOrderData(orderID) {
    return this.api.get('orders/' + orderID, {});
  }

  updateOrderStatus(orderId, data) {
    console.log('Updating order-status to ' + data.status)
    return this.api.put('orders/' + orderId, data);
  }

  setIsPackedMeta(item, orderID) {
    const data = {
      line_items: [{
        'id': item.id,
        'meta_data': [{
          'key': '_fra_packed',
          'value': !item.is_packed
        }]
      }]
    };
    return this.api.put('orders/' + orderID, data);
  }

  addNewOrderNote(orderID, note, userID = false) {

    console.log('api gesendet');
    const data = {
      note: 'JAA ES FUNKTIONIERT!!!'
    }
    return this.api.post('orders/' + orderID + '/notes', data);
  }
}

export default OrderService;
