import axios from 'axios' ;

class AuthenticationService {
  constructor() {
    console.log('Starting authentication service...');

    let site_url;
    switch(window.location.hostname) {
      case 'localhost':
        site_url = 'https://frankowitsch.test';
        break;
      case 'test-lieferschein.frankowitsch.at':
        site_url = 'https://test.frankowitsch.at';
        break;
      default:
        site_url = 'https://frankowitsch.at';
    }
    this.api_url = site_url + '/wp-json/jwt-auth/v1';
    console.log('API-url is: ' + this.api_url);

    // avoid a warning
    axios.interceptors.request.use(function (config) {
      const {headers = {}} = config || {};
      if (headers['User-Agent']) {
        delete config.headers['User-Agent'];
      }

      return config;
    })
  }

  retrieveAuthenticationToken(loginData) {
    return axios.post(`${this.api_url}/token`, loginData);
  }

  storeAuthenticationTokenInLocalStorage(token) {
    localStorage.setItem('authToken', token);
    console.log('Authentication token stored in local storage.')
  }

  retrieveAuthenticationTokenFromLocalStorage() {
    return localStorage.getItem('authToken');
  }

  deleteAuthenticationTokenFromLocalStorage() {
    localStorage.removeItem('authToken');
    console.log('Authentication token deleted from local storage.')
  }

  authenticateToken(token) {
    const header = {Authorization: `Bearer ${token}`};
    return axios.post(`${this.api_url}/token/validate`, {}, {headers: header});
  }
}

export default AuthenticationService;
