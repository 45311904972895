import React from "react";

export default class OrderOverviewCategoryCounts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      itemCounts: props.itemCounts
    };
  }

  componentDidMount() {
    this.setState({
      isLoaded: true
    })
  }

  render() {
    if (this.state.error) {
      return <div>Error: {this.state.error.message}</div>;
    } else if (!this.state.isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div className="item-counts">
          {this.state.itemCounts.map(category => (
            <div key={category.name} className={'item-counts-category ' + category.name + (category.itemCount ? '' : ' no-items')}>
              <div className="icon"/>
              {category.itemCount}
            </div>
          ))}
        </div>
      );
    }
  }
}
