import React from "react";
import {Link} from "react-router-dom";
import OrderService from "./services/order-service";
import OrderOverviewCategoryCounts from "./order-overview-components/order-overview-category-counts";
import Header from "./header";
import Navigation from "./navigation";
import Loader from "./loader";
import Login from "./login";

export default class OrderOverview
  extends React.Component {

  orderService = new OrderService();

  constructor(props) {
    super(props);

    this.updateCurrentDay = this.updateCurrentDay.bind(this);

    let todaysDatetime = new Date();
    let todaysDate = todaysDatetime.getDate() + '.' + (todaysDatetime.getMonth() + 1) + '.' + todaysDatetime.getFullYear();

    this.state = {
      error: null,
      isLoaded: false,
      selectedDateTimestamp: todaysDatetime,
      selectedDateName: this.getDaysNameOfTimestamp(todaysDatetime) + ', ' + todaysDate,
      orders: []
    };
  }

  getDaysNameOfTimestamp(timestamp) {
    return timestamp.toLocaleDateString('de-AT', {weekday: 'long'});
  }

  componentDidMount() {
    this.orderService.retrieveAllOrders(parseInt(this.state.selectedDateTimestamp.getTime() / 1000)).then(
      (result) => {
        var orderTimes = result.data.orders.map(order => {
          return order.orderTime;
        });
        orderTimes = [...new Set(orderTimes)];

        this.setState({
          isLoaded: true,
          orders: result.data.orders,
          orderTimes: orderTimes
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  updateCurrentDay(timestamp) {
    let todaysDate = timestamp.getDate() + '.' + (timestamp.getMonth() + 1) + '.' + timestamp.getFullYear();
    let todaysDateString = timestamp.getFullYear() + '-' + (timestamp.getMonth() + 1) + '-' + timestamp.getDate();

    this.setState({
      isLoaded: false,
      selectedDateTimestamp: new Date(todaysDateString),
      selectedDateName: this.getDaysNameOfTimestamp(timestamp) + ', ' + todaysDate,
    });

    this.orderService.retrieveAllOrders(parseInt(this.state.selectedDateTimestamp.getTime() / 1000)).then(
      (result) => {
        var orderTimes = result.data.orders.map(order => {
          return order.orderTime;
        });
        orderTimes = [...new Set(orderTimes)];

        this.setState({
          isLoaded: true,
          orders: result.data.orders,
          orderTimes: orderTimes,
          selectedDateTimestamp: timestamp,
          selectedDateName: this.getDaysNameOfTimestamp(timestamp) + ', ' + todaysDate,
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  render() {
    if (this.state.error) {
      return <div>Error: {this.state.error.message}<br/><Login/></div>;
    } else if (!this.state.isLoaded) {
      return (
        <div className={'order-overview is-loading'}>
          <Header template={'order-overview'} title={'Bestellungen für ' + this.state.selectedDateName}
                  updateSelectedDate={this.updateCurrentDay}/>

          <h2>Bestellungen für {this.state.selectedDateName} werden geladen...</h2>
          <Loader/>
          <Navigation/>
        </div>
      );
    } else {
      if (!this.state.orders.length) {
        return (
          <div className={'order-overview'}>
            <Header template={'order-overview'} title={'Bestellungen für ' + this.state.selectedDateName}
                    updateSelectedDate={this.updateCurrentDay}/>

            <br/><br/>Keine Bestellungen für diesen Tag
          </div>
        );
      }

      return (
        <div className={'order-overview'}>
          <Header template={'order-overview'} title={'Bestellungen für ' + this.state.selectedDateName}
                  updateSelectedDate={this.updateCurrentDay}/>

          {this.state.orderTimes.map(orderTime => {
            return (<div key={orderTime}>
              <div className="order-row__time-block-header">{orderTime}</div>
              {

                this.state.orders.filter(order => (order.orderTime === orderTime)).map(order => {
                  return (
                    <div key={order.id} className="order-row-wrapper">
                      <div className="order-row">

                        <div className={'order-info ' + order.shippingMethodSlug}>
                          <div className="order-info__header">
                            <h2>Bestellung <span className={'highlighted'}>#{order.id}</span></h2>
                          </div>

                          <div className={'order-info__content'}>
                            <div className="order-info__date">
                              {order.orderTime}
                            </div>
                            <div className={'order-info__shipping-type'}>
                              {order.shipping}
                            </div>
                          </div>
                        </div>

                        <div className={'order-status'}>
                          <div className={'order-status__wc-status ' + order.status}>
                            <span>Status: </span>
                            <span className={'order-status__wc-status--status'}>{order.statusName}</span>
                          </div>
                          <OrderOverviewCategoryCounts itemCounts={order.itemCounts}/>
                        </div>

                        <Link to={{pathname: `/order/${order.id}`}}>
                          <button className={'app-button edit-button'}>Bearbeiten</button>
                        </Link>
                        <Link to={{pathname: `/print/order/${order.id}`}}>
                          <button className={'app-button print-button'}>Drucken</button>
                        </Link>
                      </div>
                    </div>
                  )
                })
              }
            </div>);
          })
          }
          <Navigation/>
        </div>
      );
    }
  }
}
