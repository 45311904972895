import React from "react";
import OrderService from "../services/order-service";

export default class OrderDetailSubcategory extends React.Component {

  orderService = new OrderService();

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      isPacked: false,
      categorySlug: props.data.slug,
      orderID: props.orderID,
      lineItems: props.data.line_items
    };
  }

  componentDidMount() {
    this.setState({
      isLoaded: true,
      isPacked: this.areAllItemsPacked()
    });
  }

  get_is_packed(orderItem) {
    return orderItem.is_packed;
  }


  togglePacked(lineItem) {
    this.orderService.setIsPackedMeta(lineItem, this.state.orderID).then(
      (result) => {
        let stateLineItems = this.state.lineItems;

        for (let i = 0; i < stateLineItems.length; i++) {
          if (stateLineItems[i].id === lineItem.id) {
            stateLineItems[i].is_packed = !lineItem.is_packed;
          }
        }

        this.setState({
          isLoaded: true,
          lineItems: stateLineItems,
          isPacked: this.areAllItemsPacked()
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  areAllItemsPacked() {
    let items = this.state.lineItems;
    if (!items) {
      return false;
    }
    for (let i = 0; i < items.length; i++) {
      if (!items[i].is_packed) {
        this.props.changeIsPackedStatus(this.state.categorySlug, false);
        return false;
      }
    }
    this.props.changeIsPackedStatus(this.state.categorySlug, true);
    return true;
  }

  render() {
    if (this.state.error) {
      return <div>Error: {this.state.error.message}</div>;
    } else if (!this.state.isLoaded) {
      return <div>Loading...</div>;
    } else {
      let subcategoryClassnames = 'order-detail__subcategory';
      if (this.state.isPacked) {
        subcategoryClassnames += ' is-packed';
      }

      return (
        <ul className={subcategoryClassnames}>
          <li className={'order-detail__subcategory--header'} key={this.props.data.name}>
            <h4>{this.props.data.name}</h4>
            <div className="category-item-count">Anzahl: {this.props.data.item_count}</div>
          </li>
          {this.state.lineItems.map(line_item => (
            <li className={'order-detail__item' + (this.get_is_packed(line_item) ? ' is-packed' : '')} key={line_item.name} onClick={() => this.togglePacked(line_item)}>
              <div className={'order-detail__item-name'}>
                <b>{line_item.quantity}x</b> <span dangerouslySetInnerHTML={{ __html: line_item.name }}/>
              </div>
              <div className={'item-checkbox'}/>
            </li>
          ))}
        </ul>
      );
    }
  }
}
