import React from "react";
import OrderService from "../services/order-service";
import OrderDetailRootCategory from "./order-detail-root-category";

export default class OrderDetailProductTable extends React.Component {
  orderService = new OrderService();

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      orderID: props.orderID,
      isPacked: false,
      sortedItems: null
    };
  }

  componentDidMount() {
    this.orderService.getOrderDetailData(this.state.orderID).then(
      (result) => {
        console.log('component did mount: Product-table');
        console.log(result);
        this.setState({
          isLoaded: true,
          sortedItems: result.data.sortedItems
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  render() {
    if (this.state.error) {
      return <div>Error: {this.state.error.message}</div>;
    } else if (!this.state.isLoaded) {
      return <div>Artikel werden geladen.</div>;
    } else {
    if (!this.state.sortedItems) {
      return <h1>Hello, {this.props.name}</h1>;
    }

    return (
      <main>
        <>
          <h2>Artikel</h2>
          {this.state.sortedItems.map(rootCategory => (
            <OrderDetailRootCategory key={rootCategory.name} data={rootCategory} orderID={this.state.orderID}/>
          ))}
        </>
      </main>
    );
  }
  }
}
