import React from "react";
import {Link} from "react-router-dom";

export default class Navigation extends React.Component {
  render() {
    return (
      <nav className={'main-navigation'}>
        <Link to={{pathname: `/`}} className="main-qr">
          <button>HOME</button>
        </Link>

        <Link to={{pathname: `/scanner`}} className="main-qr">
          <button>Scannen</button>
        </Link>
      </nav>
    )
  }
}
